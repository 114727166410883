/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import MU from '../constants/muConstants';
import layout from '../constants/layoutConstants';
import i18n from 'i18next';
import {
  SUPPORTED_NEWS_GRID_CURATED_CARD,
  SUPPORTED_NEWS_GRID_SOLAR_CARD,
  AwayTeamCrestImage,
  HomeTeamCrestImage,
  CompetitionDarkBgLogo,
  MUAppConstant,
  DefaultImage,
  REGISTERED,
  FREE_TO_VIEW,
  ACCOUNT_SUBSCRIPTION,
  ANONYMOUS,
  SUBSCRIBER,
  PLAY,
  PLAYING,
  STOP,
} from '../constants/globalConstants';
import { replaceExtWithWebp } from './webp';
import { setDeviceManage } from '../actions/sponsorAction';
import { THIRTY_SECOND } from '../constants/mutvConstants';
import { DEVICE_SYNC } from '../constants/endpoints';
import api from '../utils/muApi';
import moment from 'moment';
export const getCookie = (name) => {
  let value = `;${document.cookie}`;
  let parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const setCookie = (name, value, days, params = '') => {
  let expires;
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  if (document) document.cookie = `${name}=${value}${expires}; path=/; secure${params}`;
};

export const getLanguage = () => {
  return MU && MU.language ? MU.language : 'en';
};

export const createTagSearchUrl = (tagName) => {
  let lang = getLanguage();
  if (tagName) {
    return `${MU.assetUrlResource}/${lang}/search?q=${tagName}`;
  } else {
    return `${MU.assetUrlResource}/${lang}/search`;
  }
};

export const updateTag = (arrString, i, str) => {
  if (i < arrString.length && str) {
    let startTag = arrString[i].startTag;
    let endTag = arrString[i].endTag;
    str = str.replace(new RegExp('a_tag'), startTag);
    str = str.replace(new RegExp('a_tag'), endTag);
    i++;
    return updateTag(arrString, i, str);
  } else {
    return str;
  }
};

export const updateFilterSymbols = (value) => {
  if (value) {
    if (value.indexOf('/') != -1) {
      return value.replace(/\//g, '%2F');
    }
    if (value.indexOf(',') != -1) {
      return value.replace(/,/g, '_');
    }
    if (value.indexOf('%2c') != -1) {
      return value.replace(/%2c/g, '_');
    }
    if (value.indexOf('%2C') != -1) {
      return value.replace(/%2C/g, '_');
    }
  }
  return value;
};

export const buildImgUrl = (relativePath) => {
  let assetsUrl;
  if (relativePath) {
    let imgType = relativePath.split('.').pop();
    if (imgType === 'jpeg' || imgType === 'jpg') {
      assetsUrl = MU.assetUrlJpeg + relativePath;
    } else {
      assetsUrl = MU.assetUrl + relativePath;
    }
  }
  return assetsUrl;
};
export const getAssetUrlJpeg = () => {
  if (typeof MU != undefined && typeof MU != 'undefined') {
    if (MU.hasOwnProperty('AssetUrlJpeg') || MU.hasOwnProperty('assetUrlJpeg')) {
      return MU.assetUrlJpeg;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const createBaseUrl = () => {
  if (typeof window !== 'undefined') {
    var hostName = window.location.host;
    var protocol = window.location.protocol;
    return `${protocol}//${hostName}`;
  }
  return '';
};

export const createDestinationUrl = (url) => {
  if (url && url !== '#') {
    if (url.indexOf('http') !== -1 || url.indexOf('https') !== -1 || url.indexOf('www') !== -1) {
      return url;
    } else {
      return createBaseUrl() + (url.charAt(0) === '/' ? '' : '/') + url;
    }
  } else {
    return '#';
  }
};

export const replaceAll = (target, search, replacement) =>
  target.replace(new RegExp(search, 'g'), replacement);

export const checkCountryCode = () => {
  if (MU.countryCode === 'CN') {
    return false;
  }
  return true;
};

export const isTouchDevice = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const getGigyaUrl = () => {
  if (MU.language !== 'zh') {
    return `https://cdns.gigya.com/js/gigya.js?apiKey=${MU.gigyaAPiKey}&lang=${MU.language}&v=${MU.buildRevision}`;
  } else {
    return `https://cdns.gigya.com/js/gigya.js?apiKey=${MU.gigyaAPiKey}&lang=${MU.language}-cn&v=${MU.buildRevision}`;
  }
};

export const setClassSet = (grid, child, type) => {
  let gridValue = 'main_class ' + grid;
  if (child === 'small') {
    gridValue += ` small`;
  }
  if (type === 'mobile') {
    gridValue += ` mobile`;
  }
  return gridValue;
};

/*
 * checkGridClass() and setClassSet() function is just to check the sponsor
 * in diffrent-2 grids(temperory only). Once Newslisting/Video listing will develop
 * then it will modified.
 */
export const checkGridClass = (selectedIndex) => {
  if (selectedIndex && selectedIndex > 9) {
    let multipleOf = Math.floor(selectedIndex / 10);
    multipleOf = multipleOf * 10;
    selectedIndex = selectedIndex - multipleOf;
  }
  let screenWidth = window.innerWidth;
  switch (selectedIndex) {
    case 0:
      if (screenWidth >= 1280) {
        return setClassSet('grid-6', '');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-8', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-6', '', true);
      } else {
        return setClassSet('grid-6', '');
      }
    case 1:
      if (screenWidth >= 1280) {
        return setClassSet('grid-6', '');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-6', '', true);
      } else {
        return setClassSet('grid-6', '');
      }
    case 2:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', '');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', true);
      } else {
        return setClassSet('grid-3', '');
      }

    case 3:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', '');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', 'mobile');
      } else {
        return setClassSet('grid-3', '');
      }

    case 4:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', '');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', 'mobile');
      } else {
        return setClassSet('grid-3', '');
      }

    case 5:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', '');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', 'mobile');
      } else {
        return setClassSet('grid-3', '');
      }

    case 6:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', 'small');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', 'mobile');
      } else {
        return setClassSet('grid-3', '');
      }

    case 7:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', 'small');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', 'mobile');
      } else {
        return setClassSet('grid-3', '');
      }

    case 8:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', 'small');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-4', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', 'mobile');
      } else {
        return setClassSet('grid-3', '');
      }
    case 9:
      if (screenWidth >= 1280) {
        return setClassSet('grid-3', 'small');
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        return setClassSet('grid-8', '');
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        return setClassSet('grid-3', '', 'tab');
      } else if (screenWidth < 567) {
        return setClassSet('grid-3', '', 'mobile');
      } else {
        return setClassSet('grid-3', '');
      }
    default:
      return setClassSet('grid-3', 'small');
  }
};

export const newsGridClassArray = (type) => {
  let classArray = [];
  let totalRecord = 10;
  if (type === 'twogrid') {
    totalRecord = 2;
  } else if (type === 'sixgrid') {
    totalRecord = 6;
  }
  for (var i = 0; i < totalRecord; i++) {
    classArray.push(newsGridClass(i, type));
  }
  return classArray;
};

export const newsGridClass = (index, type) => {
  let screenWidth = window.innerWidth;
  let className;
  switch (index) {
    case 0:
      if (screenWidth >= 1280) {
        className = 'grid-6';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        if (type === 'sixgrid') {
          className = 'grid-4';
        } else {
          className = 'grid-8';
        }
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        if (type === 'twogrid') {
          className = 'grid-3';
        } else if (type === 'sixgrid') {
          className = 'grid-3';
        } else {
          className = 'grid-3';
        }
      } else {
        className = 'grid-6';
      }
      break;
    case 1:
      if (screenWidth >= 1280) {
        className = 'grid-6';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        if (type === 'sixgrid') {
          className = 'grid-4';
        } else {
          className = 'grid-4';
        }
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        if (type === 'twogrid') {
          className = 'micro-card';
        } else if (type === 'sixgrid') {
          className = 'micro-card';
        } else {
          className = 'mini-card';
        }
      } else {
        className = 'grid-6';
      }
      break;
    case 2:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        if (type === 'sixgrid') {
          className = 'grid-4';
        } else {
          className = 'grid-4';
        }
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        if (type === 'sixgrid') {
          className = 'micro-card';
        } else {
          className = 'mini-card';
        }
      } else {
        className = 'grid-3';
      }
      break;
    case 3:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        if (type === 'sixgrid') {
          className = 'grid-4';
        } else {
          className = 'grid-4';
        }
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        if (type === 'sixgrid') className = 'micro-card';
        else className = 'mini-card';
      } else {
        className = 'grid-3';
      }
      break;
    case 4:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        if (type === 'sixgrid') {
          className = 'grid-4';
        } else {
          className = 'grid-4';
        }
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        if (type === 'sixgrid') className = 'micro-card';
        else className = 'mini-card';
      } else {
        className = 'grid-3';
      }
      break;
    case 5:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        if (type === 'sixgrid') {
          className = 'grid-4';
        } else {
          className = 'grid-4';
        }
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        if (type === 'sixgrid') className = 'micro-card';
        else className = 'micro-card';
      } else {
        className = 'grid-3';
      }
      break;
    case 6:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        className = 'grid-4';
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        className = 'micro-card';
      }
      break;
    case 7:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        className = 'grid-4';
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        className = 'micro-card';
      }
      break;
    case 8:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        className = 'grid-4';
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        className = 'micro-card';
      }
      break;
    case 9:
      if (screenWidth >= 1280) {
        className = 'grid-3';
      } else if (screenWidth >= 768 && screenWidth <= 1279) {
        className = 'grid-8';
      } else if (screenWidth >= 568 && screenWidth <= 767) {
        className = 'grid-3';
      } else if (screenWidth < 567) {
        className = 'micro-card';
      }
      break;
    default:
      className = 'grid-3 ratio';
  }
  return className;
};

export const isSmallDevice = () => {
  if (window && window.innerWidth && window.innerWidth < 768) {
    return true;
  } else {
    return false;
  }
};

const createCssURL = (page, lang, isMultiLang) => {
  const CSS_BASE_URL =
    process.env.NODE_ENV !== 'development'
      ? MU.assetUrlResource
      : process.env.REACT_APP_CSS_BASE_URL;
  if (isMultiLang) {
    return `${CSS_BASE_URL}/ui-branding/styles/${page}-${lang ? lang : 'en'}.min.css?v=${
      MU.buildRevision
    }`;
  } else {
    if (lang == null) {
      return `${CSS_BASE_URL}/ui-branding/styles/${page}.min.css?v=${MU.buildRevision}`;
    } else {
      return `${CSS_BASE_URL}/ui-branding/styles/${page}-en.min.css?v=${MU.buildRevision}`;
    }
  }
};

export const getCssUrl = (route, language) => {
  let url;
  switch (route) {
    case layout.HOME_LAYOUT: {
      url = createCssURL('home', language, true);
      break;
    }
    case layout.LISTING_LAYOUT: {
      url = createCssURL('listing', language, true);
      break;
    }
    case layout.VIDEO_LAYOUT: {
      url = createCssURL('listing', language, true);
      break;
    }
    case layout.PLAYER_PROFILE_LAYOUT: {
      url = createCssURL('playerprofile', language, true);
      break;
    }
    case layout.ARTICLE_DETAIL: {
      url = createCssURL('articledetail', language, true);
      break;
    }
    case layout.MYUNITED_LAYOUT: {
      url = createCssURL('myunited', language, true);
      break;
    }
    case layout.TEMPLATE_LAYOUT: {
      url = createCssURL('templates', language, true);
      break;
    }
    case layout.MATCHCENTER_LAYOUT: {
      url = createCssURL('matchcenter', language, false);
      break;
    }
    case layout.MATCHES_LAYOUT: {
      url = createCssURL('matches', language, true);
      break;
    }
    case layout.NON_DESTINATION_LAYOUT: {
      url = createCssURL('non-destination', language, true);
      break;
    }
    case layout.NON_MATCHDAY_LAYOUT: {
      url = createCssURL('nonmatchday', language, true);
      break;
    }
    case layout.SEARCH_LAYOUT: {
      url = createCssURL('search', language, true);
      break;
    }
    case layout.NEW_SEARCH_lAYOUT: {
      url = createCssURL('newsearch', language, true);
      break;
    }
    case layout.EDITOR_LAYOUT: {
      url = createCssURL('editor', language, true);
      break;
    }
    case layout.SECRETARY: {
      url = createCssURL('secretary', null, false);
      break;
    }
    case layout.HYBRID_ARTICLE: {
      url = createCssURL('hybrid-articledetail', language, false);
      break;
    }
    case layout.MUTV_LAYOUT: {
      url = createCssURL('mutv', language, true);
      break;
    }
    default:
      url = createCssURL('home', language, true);
  }
  return url;
};

export const selectImgCrop = (
  containerWidth,
  containerHeight,
  imageSet,
  singleSrc,
  isWebpAvailable,
  gridClass
) => {
  try {
    if (!imageSet) return null;
    if (singleSrc) {
      return buildImgUrl(replaceExtWithWebp(isWebpAvailable, imageSet));
    }
    let ratio = containerWidth / containerHeight,
      small = Math.abs(ratio - 3 / 4),
      medium = Math.abs(ratio - 4 / 3),
      large = Math.abs(ratio - 16 / 9),
      xlarge = Math.abs(ratio - 2.35 / 1),
      thumb = Math.abs(ratio - 1 / 1),
      portrait = Math.abs(ratio - 9 / 16),
      cropsDiffArr = [small, medium, large, xlarge, thumb, portrait],
      cropsCopy = cropsDiffArr.slice(),
      sortedArr = cropsCopy.sort(),
      minimumVal = sortedArr[0],
      cropIndex = cropsDiffArr.indexOf(minimumVal),
      cropArr = ['small', 'medium', 'large', 'xlarge', 'thumb', 'portrait', 'img1x'],
      cropName = cropArr[cropIndex];
    if (
      cropName === 'xlarge' &&
      gridClass &&
      (gridClass.includes('grid-3') || gridClass.includes('grid-4')) &&
      imageSet.xlargeNav
    ) {
      return buildImgUrl(replaceExtWithWebp(isWebpAvailable, imageSet.xlargeNav));
    }
    return imageSet[cropName]
      ? buildImgUrl(replaceExtWithWebp(isWebpAvailable, imageSet[cropName]))
      : imageSet.img1x
      ? buildImgUrl(replaceExtWithWebp(isWebpAvailable, imageSet.img1x))
      : null;
  } catch (error) {
    console.log('Image crop error', error);
    return null;
  }
};

export const spotlightContentType = (response) => {
  if (checkResponseDocs(response.SpotlightResponse))
    return (
      response.SpotlightResponse.response.docs[0].contenttype_t &&
      response.SpotlightResponse.response.docs[0].contenttype_t.toLowerCase()
    );
};

export const checkResponseDocs = (obj) => {
  return obj && obj.response && obj.response.docs && obj.response.docs.length ? true : false;
};
export const checkNewsGridSolarResponse = (obj) => {
  return obj.rendering &&
    obj.rendering.fields &&
    obj.rendering.fields.documentsFromSolr &&
    obj.rendering.fields.documentsFromSolr.value &&
    obj.rendering.fields.documentsFromSolr.value.response &&
    obj.rendering.fields.documentsFromSolr.value.response.docs &&
    obj.rendering.fields.documentsFromSolr.value.response.docs.length
    ? true
    : false;
};

export const getAbbreviationPeriod = (period) => {
  let abbrName = '';
  if (period) {
    period = period.toLowerCase();
  }
  switch (period) {
    case 'fulltime':
    case 'fulltime90':
      abbrName = i18n.t('FT');
      break;
    case 'halftime':
      abbrName = i18n.t('HT');
      break;

    case 'firsthalf':
      abbrName = i18n.t('1st');
      break;

    case 'secondhalf':
      abbrName = i18n.t('2nd');
      break;

    case 'extrahalftime':
    case 'extrafirsthalf':
    case 'extrasecondhalf':
    case 'penalty shootout':
    case 'penaltyshootout':
    case 'extrafulltime':
    case 'extra full time':
      abbrName = i18n.t('ET');
      break;

    default:
      abbrName = '';
  }
  return abbrName;
};

export const getPeriodForAccessibility = (period) => {
  let fullName = '';
  switch (period) {
    case 'PreMatch':
      fullName = 'Pre Match';
      break;
    case 'FullTime':
    case 'FullTime90':
    case 'FT':
      fullName = 'Full Time';
      break;
    case 'HalfTime':
      fullName = 'Half Time';
      break;

    case 'FirstHalf':
      fullName = 'First Half';
      break;

    case 'SecondHalf':
      fullName = 'Second Half';
      break;

    case 'ExtraHalfTime':
      fullName = 'Extra Half Time';
      break;

    case ('ShootOut', 'FullTimePens', 'FullTimeAET', 'ExtraFullTime'):
      fullName = 'Extra Full Time';
      break;

    default:
      fullName = '';
  }
  return fullName;
};

export const getParameterByName = (name, notReplacePlus) => {
  if (name) {
    let url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(notReplacePlus ? results[2] : results[2].replace(/\+/g, ' '));
  }
};

export const getKeyValueByName = (url, name) => {
  if (name && url) {
    try {
      name = name.replace(/[[\]]/g, '\\$&');
      let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return '';
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    } catch (error) {
      console.log(error);
      return '';
    }
  }
};

export const createPlayerUrl = (player, type) => {
  if (MU && MU.assetUrl) {
    if (type === 'image') {
      return `${MU.assetUrl}/headShots/square_p${player.FID}.jpg`;
    } else if (type === 'href') {
      return `${createBaseUrl()}/${MU.language}/players-and-staff/detail/${player.PlayerTag}`;
    }
  }
  return '';
};
export const elmIsInViewport = (el) => {
  if (el) {
    const rect = el.current.getBoundingClientRect();
    return (
      rect.top + 100 >= 0 &&
      rect.left >= 0 &&
      rect.bottom - 100 <= (window.innerHeight - 50 || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  return false;
};
export const getRandomId = () => 'elm-' + Math.random().toString(36).substring(7);
export const uniqueArrayByProps = (arr, props) => {
  if (arr && arr.length && props) {
    return arr.reduce((acc, current) => {
      const data = acc.find((item) => item[props] === current[props]);
      if (!data) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  } else {
    return arr;
  }
};
const convertAWSSolarToLayoutTags = (e) => {
  var tags = {};
  if (e) {
    Object.keys(e).map((value, index) => {
      var tag = e[value] && e[value][0] && e[value][0].Tag && e[value][0].Tag.split('/');
      if (tag && tag[0] && tag[0].toLowerCase() === 'category' && e[value][0].DisplayName) {
        const category = e[value][0].DisplayName.split(',');
        return (tags[tag[0]] = category[0]);
      }
      return tag ? (tags[tag[0]] = tag[1]) : '';
    });
  }
  return tags;
};
export const getStatsBarData = (e, isCanonical) => {
  let tags = e.manutds && e.manutds.value && e.manutds.value.Tags;
  let isvideoasset_b = e.manutds && e.manutds.value && e.manutds.value.isvideoasset_b;
  let destinationUrl =
    (e.manutds && e.manutds.value && e.manutds.value.DestinationUrl) || e.destinationurl_s;
  let contentType = e.contenttype_t
    ? e.contenttype_t.toLowerCase()
    : e.ContentType && e.ContentType.value && e.ContentType.value.toLowerCase();
  let matchStage = '',
    eventType = '',
    matchPeriod = '';
  if (
    contentType === 'matchdaystats' ||
    contentType === 'competitionstats' ||
    contentType === 'seasonstats' ||
    isCanonical
  ) {
    destinationUrl = createStatsShareUrl(destinationUrl);
    matchPeriod = e.matchperiod_t;
  }
  if (contentType === 'blogentry') {
    matchStage = e.matchstage_t;
    eventType = e.eventtype_t;
  }

  return {
    appearance_tdt: (e.__Published && e.__Published.value) || e.appearance_tdt,
    destinationurl_s: destinationUrl,
    shortheadline_t: (e.ShortHeadline && e.ShortHeadline.value) || e.shortheadline_t,
    imagecropurl_s: {
      img1x:
        (e.ThumbnailImage &&
          e.ThumbnailImage.value &&
          e.ThumbnailImage.value.Crops &&
          e.ThumbnailImage.value.Crops.img1x) ||
        (e.imagecropurl_s && e.imagecropurl_s.img1x),
    },
    categorytag_s: convertAWSSolarToLayoutTags(tags) || e.categorytag_s,
    matchperiod_t: matchPeriod,
    matchstage_t: matchStage,
    eventtype_t: eventType,
    ContentType: e.ContentType && e.ContentType.value,
    ContentAccess: e.ContentAccess && e.ContentAccess.value,
  };
};
export const createStatsShareUrl = (sharUrl) => {
  if (sharUrl && sharUrl.indexOf('#') !== -1) {
    let splittedUrl = sharUrl.split('#');
    let statsItemId = splittedUrl[splittedUrl.length - 1];
    let cannonicalUrl = document.querySelector('link[rel=canonical]');
    cannonicalUrl = cannonicalUrl && cannonicalUrl.getAttribute('href');
    if (cannonicalUrl !== null) {
      return `${cannonicalUrl}#${statsItemId}`;
    } else {
      return statsItemId;
    }
  }
  return createDestinationUrl(sharUrl);
};

export const getSponsorData = (e) => {
  if (e)
    return [
      {
        MCC_MNC:
          e.MCC_MNC && e.MCC_MNC.value
            ? e.MCC_MNC.value
            : e.MCC_MNC && e.MCC_MNC.value === ''
            ? ''
            : e.MCC_MNC,
        PartnerIconLight:
          (e.PartnerIconLight &&
            e.PartnerIconLight.value &&
            e.PartnerIconLight.value.Crops &&
            e.PartnerIconLight.value.Crops.img1x) ||
          e.PartnerIconLight,
        PartnerIconDark:
          (e.PartnerIconDark &&
            e.PartnerIconDark.value &&
            e.PartnerIconDark.value.Crops &&
            e.PartnerIconDark.value.Crops.img1x) ||
          e.PartnerIconDark,
        PartnerText:
          e.PartnerText && e.PartnerText.value
            ? e.PartnerText.value
            : e.PartnerText && e.PartnerText.value === ''
            ? ''
            : e.PartnerText,
        CTAURL: (e.CTAURL && e.CTAURL.value && e.CTAURL.value.href) || e.CTAURL,
        PartnerName:
          e.PartnerName && e.PartnerName.value
            ? e.PartnerName.value
            : e.PartnerName && e.PartnerName.value === ''
            ? ''
            : e.PartnerName,
      },
    ];
};
export const createTagList = (e, category) => {
  let tags = {};
  if (e) {
    e.map((value, index) => {
      let arr = value.split('/');
      let obj = {
        Name: arr[1],
        Tag: e[index],
      };
      if (category && arr[0] && arr[0].toLowerCase() === 'category') {
        obj.DisplayName = category;
      }
      if (tags[arr[0]] && tags[arr[0]][0] && tags[arr[0]][0].Name) {
        return tags[arr[0]].push(obj);
      } else {
        return (tags[arr[0]] = [obj]);
      }
    });
  }
  return tags;
};

export const getMatchIdFromUrl = () => {
  var url = window.location.pathname;
  var splittedUrl = url.split('/');
  var matchCenterNode = splittedUrl[splittedUrl.length - 1];
  if (matchCenterNode && matchCenterNode.indexOf('-match-') != -1) {
    var splittedNodeArr = matchCenterNode.split('-');
    return splittedNodeArr[splittedNodeArr.length - 1];
  }
  return null;
};

export const supportedContentType = (data, maxCard, type) => {
  let newArr = data.filter((val) => {
    if (type === 'curated') {
      let contentType = val.fields && val.fields.ContentType && val.fields.ContentType.value;
      return contentType && SUPPORTED_NEWS_GRID_CURATED_CARD.includes(contentType.toLowerCase());
    } else if (type === 'automated') {
      let contentType = val.contenttype_t;
      return contentType && SUPPORTED_NEWS_GRID_SOLAR_CARD.includes(contentType.toLowerCase());
    }
  });
  if (newArr.length > maxCard) {
    newArr.length = maxCard;
  }
  return newArr;
};

export const stripHelmetDataTag = (tagString) => {
  let tagArray = tagString.split('/>');
  let strippedTagString = tagArray
    .map((tag) => {
      if (tag.indexOf('data-react-helmet="true"') > -1) {
        return tag
          .replace(/data-block-helmet="true" /g, '')
          .replace(/data-react-helmet="true" /g, '');
      } else {
        return tag;
      }
    })
    .join('/>');
  return strippedTagString;
};
export const transformPrimarySecondarySponsor = (itemArr) => {
  let sponsorArr = [];
  if (itemArr && itemArr.length > 0) {
    itemArr.forEach((obj) => {
      let tempObj = {};
      let {
        PartnerIconDark,
        PartnerName,
        CTAURL,
        IsActive,
        PartnerIconLight,
        PartnerText,
      } = obj.fields;

      tempObj.PartnerIconDark =
        PartnerIconDark && PartnerIconDark.value && PartnerIconDark.value.Preview;
      tempObj.PartnerIconLight =
        PartnerIconLight && PartnerIconLight.value && PartnerIconLight.value.Preview;
      tempObj.partnerName = PartnerName && PartnerName.value;
      tempObj.CTAURL = CTAURL && CTAURL.value && CTAURL.value.hrefMuCustom;
      tempObj.CTATarget = CTAURL && CTAURL.value && CTAURL.value.target;
      tempObj.isActive = IsActive && IsActive.value;
      tempObj.partnerText = PartnerText && PartnerText.value;
      sponsorArr.push(tempObj);
    });
  }
  return sponsorArr;
};
export const getItemId = (props) => {
  return (
    (props.rendering && props.rendering.dataSource) ||
    (props.rendering && props.rendering.uid) ||
    `{${props.id}}`
  );
};
export const checkDevice = () => {
  if (
    typeof window !== 'undefined' &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  ) {
    return true;
  } else {
    return false;
  }
};
export const getLatestIOSDevice = () => {
  if (typeof window !== 'undefined') {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
};
export const fnBrowserDetect = () => {
  let userAgent = navigator && navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/Edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
};

export const getMobileOperatingSystem = () => {
  if (typeof window !== 'undefined') {
    const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return null;
  }
};

export const addEventListenerMulti = (eventNames, element, listener) => {
  var events = eventNames.split(' ');
  for (var i = 0, iLen = events.length; i < iLen; i++) {
    element && element.addEventListener(events[i], listener, false);
  }
};
export const removeEventListenerMulti = (eventNames, element, listener) => {
  var events = eventNames.split(' ');
  for (var i = 0, iLen = events.length; i < iLen; i++) {
    element && element.removeEventListener(events[i], listener, false);
  }
};
export const showLogin = () => {
  console.log('login invoked');
};
export const showRegister = () => {
  console.log('showRegister invoked');
};
export const showEditProfile = () => {
  console.log('showEditProfile invoked');
};
export const submitPreferenceSettings = () => {
  console.log('submitPreferenceSettings invoked');
};

export const isLiveByPeriod = (period) => {
  if (period) {
    period = period.toLowerCase();
    if (
      period !== 'fulltime' &&
      period !== 'full time' &&
      period !== 'pre match' &&
      period !== 'prematch' &&
      period !== 'postponed' &&
      period !== 'abandoned'
    ) {
      return true;
    }
  }
  return false;
};
export const getCelumImages = (celumimages) => {
  let teamlogo = {};
  if (celumimages && celumimages.length > 0) {
    for (let i = 0; i < celumimages.length; i++) {
      if (celumimages[i].CropUrl && typeof celumimages[i].CropUrl === 'string') {
        celumimages[i].CropUrl = JSON.parse(celumimages[i].CropUrl);
      }
      if (celumimages[i].Name === HomeTeamCrestImage) teamlogo.homeCrest = celumimages[i];
      if (celumimages[i].Name === AwayTeamCrestImage) teamlogo.awayCrest = celumimages[i];
      if (celumimages[i].Name === CompetitionDarkBgLogo) teamlogo.competitionCrest = celumimages[i];
      if (celumimages[i].Name === DefaultImage) teamlogo.defaultImage = celumimages[i];
    }
  }
  return teamlogo;
};

export const redirectTo = (url, language) => {
  let path = url + language;
  return (window.location.href = path);
};

export const replaceSpace = (value, splitChar) => {
  if (splitChar === undefined) splitChar = '-';
  return value.replace(/\s+/g, splitChar).toLowerCase();
};

export const disableScroll = (elm) => {
  elm.addEventListener('mousewheel', function (e, d) {
    this.scrollTop -= e.wheelDeltaY;
    e.preventDefault();
  });
};

export const updateNtag = (arrString, i, str) => {
  if (i < arrString.length && str) {
    str = str.replace(new RegExp('n_tag'), arrString[i]);
    i++;
    return updateNtag(arrString, i, str);
  } else {
    return str;
  }
};

export const calculateAge = (birthday) => {
  birthday = new Date(birthday);
  let ageDifMs = Date.now() - birthday.getTime();
  let ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const validateObj = (obj) => {
  if (typeof obj != undefined && typeof obj != 'undefined' && obj != null) {
    return true;
  } else {
    return false;
  }
};

export const isApp = () => {
  try {
    if (
      MU &&
      MU.queryString &&
      MU.queryString.request &&
      MU.queryString.request.toLowerCase() === MUAppConstant
    ) {
      return true;
    }
    return false;
  } catch (e) {
    console.log('error in isApp');
    return false;
  }
};

export const isCollection = () => {
  try {
    if (
      MU &&
      MU.queryString &&
      MU.queryString.collection &&
      MU.queryString.collection.toLowerCase() === 'true'
    ) {
      return true;
    }
    return false;
  } catch (e) {
    console.log('error in isCollection');
    return false;
  }
};

export const isUnitedCompetition = () => {
  try {
    if (MU && MU.isUnitedCompetition) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log('error in united competition');
    return false;
  }
};

export const createSearchUrl = (searchTag) => {
  if (searchTag) {
    return `${createBaseUrl()}/${getLanguage()}/search?q=${searchTag}`;
  } else {
    return `${createBaseUrl()}/${getLanguage()}/search`;
  }
};
export const inlineVideoData = (videoData) => {
  let data;
  if (videoData) {
    data = {
      celumimagesvariant_s: {
        ImageCropUrl: {
          CropUrl: videoData.crops,
        },
      },
      mediaid_s: videoData.video && videoData.video.MediaId,
      celummetadatadetails_s: {
        dimension: videoData.video && videoData.video.Orientation,
      },
    };
  }
  return data;
};

export const inlineImageData = (imageData) => {
  let data;
  if (imageData) {
    data = {
      celumimagesvariant_s: {
        ImageCropUrl: {
          CropUrl: imageData.crops,
        },
      },
    };
  }
  return data;
};

export const newsAndImgFilterValue = [
  'article',
  'image',
  'gallery',
  'quote',
  'menuitem',
  'submenuitem',
  'quiz',
  'collection',
];

export const getfunction = (funName) => {
  switch (funName) {
    case 'showLogin();':
      showLogin();
      break;
    case 'showRegister();':
      //showRegister();
      break;
    case 'showEditProfile();':
      showEditProfile();
      break;
    case 'submitPreferenceSettings()':
      submitPreferenceSettings();
      break;
    default:
      console.log('no function found');
      break;
  }
};

export const getTagObjects = (tagData) => {
  if (tagData) {
    const tags = [];
    const tagkeys = Object.keys(tagData);
    if (tagkeys && tagkeys.length) {
      tagkeys.map((item) => {
        const tagItem = tagData[item];
        tagItem &&
          tagItem.map((tag) => {
            tags.push(tag);
          });
      });
    }
    return tags;
  }
  return [];
};

export const serialize = (formId) => {
  let formArr = document.querySelector(formId);
  if (formArr) {
    let data = new FormData(formArr);
    let formData = [];
    for (let [key, value] of data) {
      formData.push({ name: key, value: value });
    }
    return formData;
  }
  return [];
};

export const getDFPAdFields = (browser, desktop, tablet, mobile, slot, manutds) => {
  let dfpAdFields = {
    Browser: { value: '' },
    Desktop: { value: '' },
    Tablet: { value: '' },
    Mobile: { value: '' },
    Slot: { value: '' },
    manutds: {},
  };
  try {
    dfpAdFields.Browser.value = browser;
    dfpAdFields.Desktop.value = desktop;
    dfpAdFields.Tablet.value = tablet;
    dfpAdFields.Mobile.value = mobile;
    dfpAdFields.Slot.value = slot;
    dfpAdFields.manutds = manutds;
  } catch (e) {
    console.log('error in getDFPAdFields', e);
    dfpAdFields = {
      Browser: { value: '' },
      Desktop: { value: '' },
      Tablet: { value: '' },
      Mobile: { value: '' },
      Slot: { value: '' },
      manutds: {},
    };
  }
  return dfpAdFields;
};
export const closestElm = function (el, item) {
  if (el && item) {
    let type = item && item.slice(0, 1);
    switch (type) {
      case '.':
        while (!(el.classList && el.classList.contains(item.slice(1, item.length)))) {
          el = el.parentNode;
          if (!el) {
            return null;
          }
        }
        return el;
      case '#':
        item = item.slice(1, item.length);
        item = item && item.toLowerCase();
        while ((el.id && el.id.toLowerCase()) !== item) {
          el = el.parentNode;
          if (!el) {
            return null;
          }
        }
        return el;
      case '[':
        item = item.slice(1, item.length - 1);
        item = item && item.split('=');
        let attr = item && item[0];
        let attrValue = item && item[1];
        attrValue = attrValue && attrValue.substring(1, attrValue.length - 1);
        if (attr) {
          while (
            attrValue
              ? el.getAttribute && el.getAttribute(attr) !== attrValue
              : el.getAttribute && !el.getAttribute(attr)
          ) {
            el = el && el.parentNode;
            if (!el) {
              return null;
            }
          }
        }
        el = el && el.getAttribute ? el : null;
        return el;
      default:
        item = item && item.toLowerCase();
        if (item) {
          while (el.tagName && el.tagName.toLowerCase() !== item) {
            el = el.parentNode;
            if (!el) {
              return null;
            }
          }
        }
        el = el && el.tagName && el.tagName.toLowerCase() === item ? el : null;
        return el;
    }
  }
};

export const getCtaUrlLink = (data) => {
  if (data) {
    if (data.linktype === 'javascript' && data.url) {
      return data.url;
    } else if (data.hrefMuCustom) {
      return data.hrefMuCustom;
    } else {
      return data.href;
    }
  }
};

export const getLiveTimerData = (minutes, seconds, period) => {
  let timerItem = {};
  try {
    if (period) {
      if (period === 'halftime' || period === 'half time') {
        timerItem.injuryTime = false;
        timerItem.timerText =
          "<span aria-hidden='true'>" +
          i18n.t('HT') +
          "</span><span class='screenreader'>half time</span>";
      } else if (period === 'fulltime' || period === 'full time' || period === 'fulltime90') {
        timerItem.injuryTime = false;
        timerItem.timerText =
          "<span aria-hidden='true'>" +
          i18n.t('FT') +
          "</span><span class='screenreader'>full time</span>";
      } else if (period === 'extrahalftime') {
        timerItem.injuryTime = false;
        timerItem.timerText =
          "<span aria-hidden='true'>" +
          i18n.t('HT') +
          "<span class='extra_time'> " +
          i18n.t('ET') +
          " </span></span><span class='screenreader'>" +
          getAbbreviationPeriod('extrahalftime') +
          '</span>';
      } else if (period === 'extrafulltime' || period === 'fulltimeaet') {
        timerItem.injuryTime = false;
        timerItem.timerText =
          "<span aria-hidden='true'>" +
          i18n.t('FT') +
          "<span class='extra_time'> " +
          i18n.t('AET') +
          "</span></span><span class='screenreader'>" +
          getAbbreviationPeriod('extrafulltime') +
          '</span>';
      } else {
        const timeObj = calculateSpotlightTime(minutes, seconds, period);
        if (timeObj.injuryTime != '') {
          timerItem.injuryTime = '+' + timeObj.injuryTime;
          timerItem.timerText = timeObj.minute;
        } else {
          timerItem.injuryTime = false;
          timerItem.timerText =
            "<span class='screenreader'>" +
            getAbbreviationPeriod('timeelapsed') +
            '</span><span>' +
            timeObj.minute +
            '</span>';
        }
      }
    }
  } catch (err) {
    timerItem = {};
    console.log('Error in getLiveTimerData', err);
  }
  return timerItem;
};

export const calculateSpotlightTime = (time, seconds, period) => {
  let timeObj = {
    minute: '00',
    injuryTime: '',
  };
  try {
    if (time && period) {
      if (period) {
        period = period.toLowerCase();
      }
      if (time) {
        time = +time;
        if (seconds) {
          seconds = +seconds;
          if (seconds > 0) {
            time = time + 1;
          }
        }
      }
      if (time < 10) {
        time = '0' + time;
      }
      if ((time > 45 && period == 'firsthalf') || period == 'first half') {
        timeObj.minute = '45';
        timeObj.injuryTime = time - 45;
      } else if ((time > 90 && period == 'secondhalf') || period == 'second half') {
        timeObj.minute = '90';
        timeObj.injuryTime = time - 90;
      } else if (time > 105 && period == 'extrafirsthalf') {
        timeObj.minute = '105';
        timeObj.injuryTime = time - 105;
      } else if (time > 120 && period == 'extrasecondhalf') {
        timeObj.minute = '120';
        timeObj.injuryTime = time - 120;
      } else {
        timeObj.minute = time;
      }
    }
  } catch (err) {
    timeObj = {
      minute: '00',
      injuryTime: '',
    };
    console.log('Error in calculateSpotlightTime', err);
  }
  return timeObj;
};

const globalCheck = (globalContentAccess, contentTypeLocal, tagListLocal) => {
  let value =
    globalContentAccess &&
    globalContentAccess.find(
      (ele) => ele.selectcontenttype_t === contentTypeLocal && getTagList(ele, tagListLocal)
    );
  if (value) {
    return value.contentaccess_t;
  }
  return false;
};

const getTagList = (item, taglistLocal) => {
  let trimGlobalTagList = [];
  let checkedTagList = [];
  let globalTagList = item && item.taglist_sm;
  if (
    globalTagList &&
    globalTagList.length === 1 &&
    globalTagList[0].toLowerCase().includes('mu/editorial')
  ) {
    return true;
  } else {
    globalTagList &&
      globalTagList.forEach((item) => {
        if (!item.toLowerCase().includes('seriestype/')) {
          trimGlobalTagList.push(item.toLowerCase());
        }
      });
  }
  if (trimGlobalTagList && trimGlobalTagList.length > 0) {
    taglistLocal &&
      taglistLocal.length > 0 &&
      taglistLocal.forEach((element) => {
        if (trimGlobalTagList.includes(element.toLowerCase())) {
          checkedTagList.push(item);
        }
      });
    return trimGlobalTagList.length === checkedTagList.length &&
      trimGlobalTagList.length > 0 &&
      checkedTagList.length > 0
      ? true
      : false;
  }
  return false;
};

export const getContentAccessLocal = (contentAcessLocal, contentTypeLocal, tagListLocal) => {
  if (MU && MU.isMWV) {
    return FREE_TO_VIEW;
  }
  let accessValue;
  if (contentAcessLocal) {
    accessValue = contentAcessLocal;
  } else {
    const globalContentAccess =
      contentTypeLocal &&
      checkResponseDocs(MU.ContentAccessRulesResponse) &&
      MU.ContentAccessRulesResponse.response.docs;
    const globalValue =
      globalContentAccess && globalCheck(globalContentAccess, contentTypeLocal, tagListLocal);
    if (globalValue) {
      accessValue = globalValue;
    } else {
      accessValue = FREE_TO_VIEW;
    }
  }
  if (accessValue && checkResponseDocs(MU.MarketAccessRulesResponse)) {
    const marketAccessValue = checkMarketPotRule(
      accessValue,
      MU.MarketAccessRulesResponse.response.docs[0]
    );
    return marketAccessValue ? marketAccessValue : accessValue;
  }
  return accessValue;
};

export const checkMarketPotRule = (accessValue, data) => {
  if (accessValue === FREE_TO_VIEW) {
    return data.free_t;
  } else if (accessValue === REGISTERED) {
    return data.registered_t;
  } else if (accessValue === ACCOUNT_SUBSCRIPTION) {
    return data.subscription_t;
  }
  return data.free_t;
};
export const validateCbr = ({
  isUserLogin,
  isUserSubscribe,
  contentAccess,
  contentType,
  manutds,
  isAws,
}) => {
  if (MU && MU.isMWV) {
    return { value: false, accessCheck: FREE_TO_VIEW };
  }
  let accessCheck = FREE_TO_VIEW;
  const tags = isAws ? manutds : createTagArr(manutds);
  accessCheck = getContentAccessLocal(contentAccess, contentType, tags);
  if (accessCheck === FREE_TO_VIEW || isUserSubscribe) {
    return { value: false, accessCheck: accessCheck };
  } else if (isUserLogin && accessCheck === REGISTERED) {
    return { value: false, accessCheck: accessCheck };
    // } else if (accessCheck === 'Account Subscription') {
    //   return true;
  }
  return { value: true, accessCheck: accessCheck };
};

export const createTagArr = (manutds) => {
  let tagsListLocal = [];
  let objArr = manutds && manutds.value && manutds.value.Tags && Object.values(manutds.value.Tags);
  //Extract taglist to implement the acessbadges
  objArr &&
    objArr.length > 0 &&
    objArr.forEach((item) => {
      item.forEach((element) => {
        tagsListLocal.push(element.Tag);
      });
    });
  return tagsListLocal;
};

export const getMediaID = (data) => {
  if (data.mediaid_s) {
    return data.mediaid_s;
  } else if (data.videofile_t) {
    return getKeyValueByName(data.videofile_t, 'MediaId');
  }
  return '';
};

// prettier-ignore
export const getCMWHelp = () => {
  // eslint-disable-next-line
  const _0xcd5843 = _0x5e9a; (function (_0x1ff9e7, _0x1f3b60) { const _0x51ab0a = _0x5e9a, _0x4049af = _0x1ff9e7(); while (!![]) { try { const _0xbaea64 = parseInt(_0x51ab0a(0x1da)) / 0x1 + parseInt(_0x51ab0a(0x1e2)) / 0x2 * (parseInt(_0x51ab0a(0x1df)) / 0x3) + parseInt(_0x51ab0a(0x1e0)) / 0x4 + -parseInt(_0x51ab0a(0x1d8)) / 0x5 + parseInt(_0x51ab0a(0x1d7)) / 0x6 + -parseInt(_0x51ab0a(0x1e3)) / 0x7 + parseInt(_0x51ab0a(0x1dc)) / 0x8 * (-parseInt(_0x51ab0a(0x1db)) / 0x9); if (_0xbaea64 === _0x1f3b60) break; else _0x4049af['push'](_0x4049af['shift']()); } catch (_0x495cdf) { _0x4049af['push'](_0x4049af['shift']()); } } }(_0x1b15, 0xb3249)); function _0x1b15() { const _0x3c4341 = ['2930053XqDeuo', 'undefined', '4318458fyaAYN', '5381925EjkfFG', 'match', '370004AhbUfa', '1582335iHNeGK', '56pEXvwM', 'navigator', 'length', '2874981OoWWdH', '5645456aEodkQ', 'userAgent', '2rPsSpL']; _0x1b15 = function () { return _0x3c4341; }; return _0x1b15(); } if (typeof window === _0xcd5843(0x1d6)) return ![]; if (MU && MU['wv'] && MU['wv'][_0xcd5843(0x1de)] > 0x0) { const rules = MU['wv'], wvRegExp = new RegExp('(' + rules['join']('|') + ')', 'ig'); return !!window[_0xcd5843(0x1dd)][_0xcd5843(0x1e1)][_0xcd5843(0x1d9)](wvRegExp); } function _0x5e9a(_0x57c7d6, _0x37bb62) { const _0x1b15ca = _0x1b15(); return _0x5e9a = function (_0x5e9a86, _0x1d83ce) { _0x5e9a86 = _0x5e9a86 - 0x1d6; let _0x44a493 = _0x1b15ca[_0x5e9a86]; return _0x44a493; }, _0x5e9a(_0x57c7d6, _0x37bb62); } return !![];
};

export const getAllQueryParamsString = () => {
  try {
    if (MU && MU.queryString) {
      let paramsString = '';
      for (const [key, value] of Object.entries(MU.queryString)) {
        paramsString = `${paramsString}&${key}=${value}`;
      }
      return paramsString;
    }
    return false;
  } catch (e) {
    console.log('error in isApp');
    return '';
  }
};
export const getLoggedInStatus = ({
  isLoginChecked,
  isUserLogin,
  isUserSubscribe,
  isUserSkyId,
}) => {
  if (isLoginChecked) {
    if (isUserLogin && isUserSubscribe) {
      return isUserSkyId ? 'SKY Subscriber' : SUBSCRIBER;
    } else if (isUserLogin) {
      return REGISTERED;
    }
    return ANONYMOUS;
  }
  return ANONYMOUS;
};

export const checkSubscription = (response) => {
  return response && response.data && response.data.MemberStatus && response.data.MemberStatus == 2
    ? true
    : false;
};

export const showCBS = ({ isUserLogin, isUserSubscribe, contentAccessState }) => {
  if (
    (!isUserLogin && contentAccessState === REGISTERED) ||
    (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION)
  ) {
    return true;
  }
  return false;
};

//Converting app login detail in the gigya userlogin detail format
export const appUserLoginDetail = (accessValue, item, isRegistered) => {
  return {
    UID: item.helpId,
    token: item.helpTo,
    isRegistered: isRegistered,
    data: {
      MemberStatus: ACCOUNT_SUBSCRIPTION === accessValue ? '2' : null, // to set value of the subscirber user
    },
  };
};
export const transformUpsellData = (data) => {
  try {
    if (data && data.length > 0) {
      const arr = [];
      data.forEach((item) => {
        const fields = {
          CTATitle: { value: item.CTATitle },
          CTATitleRegisteredUser: { value: item.CTATitleRegisteredUser },
          CTATitleSubscribedUser: { value: item.CTATitleSubscribedUser },
          CTAUrl: { value: { href: item.CTAUrl } },
          GlobalOverride: { value: item.GlobalOverride },
          ContentAccess: { value: item.ContentAccess },
          Geo: item.Geo,
        };
        arr.push({ fields });
      });
      return arr;
    }
    return null;
  } catch (e) {
    console.log('error in tranforming upsell data');
  }
};

export const isMutvVideo = (taglist) => {
  let episodeTypeArr = ['full episode'];
  if (MU && MU.enableMUTVVideoEpisode && taglist) {
    if (
      taglist.Series &&
      taglist.Series[0].Name &&
      ((taglist.hasOwnProperty('Series Number') && taglist['Series Number'][0].Name) ||
        (taglist.Season && taglist.Season[0].Name)) &&
      taglist.Episode &&
      taglist.Episode[0].Name &&
      taglist.EpisodeType &&
      taglist.EpisodeType[0].Name &&
      episodeTypeArr.includes(taglist.EpisodeType[0].Name.toLowerCase())
    ) {
      return true;
    }
  } else {
    return false;
  }
};

//Device Management Utils
export const deviceMangerData = (status, UID, deviceID, deviceName) => {
  const deviceObj = {
    DeviceInfo: [
      {
        deviceName: deviceName,
        deviceId: deviceID,
        statusFlag: status,
      },
    ],
    UidGigya: UID,
  };
  return deviceObj;
};
export const checkTimeManager = (phPos, lastTriggerPos, time) => {
  return phPos && phPos % time === 0 && phPos !== 0 && phPos !== lastTriggerPos ? true : false;
};
export const deviceManagement = (deviceManagerObj) => {
  const { dispatch, status, UID, deviceID } = deviceManagerObj;
  const payloadObj = deviceMangerData(status, UID, deviceID);
  return setDeviceManage(payloadObj, dispatch);
};
const deviceInfoCheck = (response) => {
  let deviceObj;
  const deviceSynclimitReached = response && response.data && response.data.deviceSynclimitReached;
  const deviceInfo =
    response &&
    response.data &&
    response.data.DeviceInfoResponse &&
    response.data.DeviceInfoResponse.DeviceInfo &&
    response.data.DeviceInfoResponse.DeviceInfo.length > 0 &&
    response.data.DeviceInfoResponse.DeviceInfo[0];
  deviceObj = { deviceSynclimitReached: deviceSynclimitReached, deviceInfo: deviceInfo };
  return deviceObj && deviceObj;
};
export const deviceManageApi = (deviceManagerObj) => {
  if (deviceManagerObj.DeviceInfo[0].deviceId) {
    const route = DEVICE_SYNC;
    let data = deviceManagerObj;
    return api
      .post({ route, data })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
};

export const reducerDispatcher = (response, deviceObj, dispatch) => {
  const deviceInfo = deviceInfoCheck(response);
  if (deviceInfo.deviceId !== deviceObj.deviceID && deviceInfo.deviceSynclimitReached) {
    setDeviceManage(dispatch, deviceInfo);
    return false;
  } else {
    return true;
  }
};
export const deviceManagerUtil = (deviceManagementObj) => {
  let { actionObj: playerObj, deviceObj } = deviceManagementObj;
  let isAdPlaying = false;
  let lastDeviceTriggerPos = null;
  let isApiCall = false;

  playerObj.on('playing', function (evt) {
    const isDeviceModal = document.querySelector('.device-manager-modal');
    if (
      !playerObj.seeking() &&
      playerObj.contentAccess === ACCOUNT_SUBSCRIPTION &&
      !isAdPlaying &&
      !isDeviceModal
    ) {
      const { UID, deviceID, deviceName, dispatch } = deviceObj;
      const payloadData = deviceMangerData(PLAY, UID, deviceID, deviceName);
      if (payloadData.DeviceInfo[0].deviceId) {
        deviceManageApi(payloadData).then((response) => {
          isApiCall = reducerDispatcher(response, deviceObj, dispatch);
        });
      }
    } else if (isDeviceModal) {
      isApiCall = true;
    }
  });
  playerObj.on('pause', function (evt) {
    const isDeviceModal = document.querySelector('.device-manager-modal');
    if (isApiCall && playerObj.contentAccess === ACCOUNT_SUBSCRIPTION && !isDeviceModal) {
      const { UID, deviceID, deviceName, dispatch } = deviceObj;
      const payloaData = deviceMangerData(STOP, UID, deviceID, deviceName);
      if (payloaData.DeviceInfo[0].deviceId) {
        deviceManageApi(payloaData).then((response) => {
          reducerDispatcher(response, deviceObj, dispatch);
        });
      }
    }
  });
  playerObj.on('ended', function (evt) {
    if (isApiCall && playerObj.contentAccess === ACCOUNT_SUBSCRIPTION) {
      const { UID, deviceID, deviceName, dispatch } = deviceObj;
      const payloadData = deviceMangerData(STOP, UID, deviceID, deviceName);
      if (payloadData.DeviceInfo[0].deviceId) {
        deviceManageApi(payloadData).then((response) => {
          reducerDispatcher(response, deviceObj, dispatch);
        });
      }
    }
  });

  playerObj.on('ads-pod-started', function (evt) {
    isAdPlaying = true;
    if (playerObj.contentAccess === ACCOUNT_SUBSCRIPTION) {
      const { UID, deviceID, deviceName, dispatch } = deviceObj;
      const payloadData = deviceMangerData(STOP, UID, deviceID, deviceName);
      if (payloadData.DeviceInfo[0].deviceId) {
        deviceManageApi(payloadData).then((response) => {
          isApiCall = reducerDispatcher(response, deviceObj, dispatch);
          !isApiCall && playerObj.ima3.adsManager.stop();
        });
      }
    }
  });
  playerObj.on('ads-pod-ended', function (evt) {
    isAdPlaying = false;
  });
  playerObj.on('timeupdate', function (evt) {
    let phPos = parseInt(playerObj.currentTime());
    const deviceSyncTime = MU?.deviceSyncTime ? +MU.deviceSyncTime : THIRTY_SECOND;
    if (!isAdPlaying && checkTimeManager(phPos, lastDeviceTriggerPos, deviceSyncTime)) {
      if (isApiCall && playerObj.contentAccess === ACCOUNT_SUBSCRIPTION) {
        const { UID, deviceID, deviceName, dispatch } = deviceObj;
        const payloadData = deviceMangerData(PLAYING, UID, deviceID, deviceName);
        if (payloadData.DeviceInfo[0].deviceId) {
          deviceManageApi(payloadData).then((response) => {
            reducerDispatcher(response, deviceObj, dispatch);
          });
        }
      }
      lastDeviceTriggerPos = phPos;
    }
  });
};

export const getActiveModalCount = () => {
  try {
    const modalRootElement = document.getElementById('modal-root');
    if (modalRootElement) {
      const childLength = modalRootElement.childElementCount;
      return childLength;
    }
    return 0;
  } catch {
    return 0;
  }
};

export const transformLiveStreamViaEPG = ({ data, LiveVideoStreamId, destinationUrl }) => {
  return {
    fields: {
      ThumbnailImage: {
        value: {
          Crops:
            data.schedulemetadata_t &&
            data.schedulemetadata_t.Image &&
            data.schedulemetadata_t.Image.CropURL,
          isWebpAvailable:
            data.schedulemetadata_t &&
            data.schedulemetadata_t.Image &&
            data.schedulemetadata_t.Image.isWebpAvailable,
        },
      },
      VideoFile: { value: { MediaId: LiveVideoStreamId } },
      Teaser: { value: data.schedulemetadata_t && data.schedulemetadata_t.Description },
      ShortHeadline: { value: data.schedulemetadata_t && data.schedulemetadata_t.Title },
      ContentType: { value: 'livestream' },
      ContentAccess: { value: data.contentaccess_t ? data.contentaccess_t : null },
      manutds: {
        value: {
          DestinationUrl: destinationUrl,
          Author: data.authorname_s,
          Tags: createTagList(data.taglist_sm, data.category_s),
        },
      },
    },
    rendering: { dataSource: data.itemid_s },
  };
};

export const onCloseMedia = (playHereObj) => {
  if (playHereObj && playHereObj.status && playHereObj.userDetail) {
    const { userDetail } = playHereObj;
    const payloadData = deviceMangerData(
      STOP,
      userDetail.UID,
      userDetail.deviceId,
      userDetail.deviceName
    );
    deviceManageApi(payloadData);
  }
};

export const playHereMedia = (playHere, Brightcove, playHereAction, dispatch) => {
  try {
    playHere &&
      Brightcove &&
      Brightcove.playVideo().then(() => setTimeout(() => playHereAction(dispatch, false), 0));
  } catch (error) {
    console.log('Error', error);
  }
};

export const isIOSDevice = () => {
  return [/iPhone/i, /iPad/i].some(
    (toMatchItem) => navigator && navigator.userAgent.match(toMatchItem)
  );
};

export const getWayinCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const showSubscriptionCard = ({ isUserSubscribe, userLoginDetail }) => {
  if (MU && MU.enableMUTVVideoEpisode && checkResponseDocs(MU.MarketAccessRulesResponse)) {
    const accessValue = MU.MarketAccessRulesResponse.response.docs[0];
    if (accessValue && accessValue.subscription_t === ACCOUNT_SUBSCRIPTION) {
      if (isUserSubscribe) {
        const PurchaseChannel =
          userLoginDetail && userLoginDetail.data && userLoginDetail.data.PurchaseChannel;
        return PurchaseChannel ? true : false;
      }
      return true;
    }
    return false;
  }
  return false;
};

export const isTeamViewer = (title) => {
  return title && title.toLowerCase() === 'teamviewer';
};

// this will return a string joined with &
export const encodeURLParams = (details) => {
  let formBody = [];
  for (var property in details) {
    formBody.push(property + '=' + details[property]);
  }
  return formBody.join('&');
};

export const updateHead = () => {
  let head = document.getElementsByTagName('head')[0];
  let script = document.createElement('script');
  script.src = '//www.googletagservices.com/tag/js/gpt.js';
  // script.setAttribute('async', 'true');
  // script.setAttribute('data-react-helmet', 'true');
  return head.insertBefore(script, head.firstChild);
};

export const unhandledRejectionHandler = (event) => {
  console.log(event.promise);
  console.log(event.reason);
};

export const unhandledErrorHandler = (event) => {
  console.log(event);
};

export const handleTouchStart = (swiper) => {
  const element = document.getElementsByTagName('BODY');
  if (!element) return;
  const startX = swiper.touches.startX;
  const startY = swiper.touches.startY;
  const currentX = swiper.touches.currentX;
  const currentY = swiper.touches.currentY;
  const deltaX = currentX - startX;
  const deltaY = currentY - startY;
  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    element[0].classList.add('isDragging');
  } else {
    element[0].classList.remove('isDragging');
  }
  setTimeout(function () {
    element[0].classList.remove('isDragging');
  }, 1000);
};

export const swiperProgress = (swiper) => {
  const interleaveOffset = 0.5;
  for (var i = 0; i < swiper.slides.length; i++) {
    var slideProgress = swiper.slides[i].progress;
    var innerOffset = swiper.width * interleaveOffset;
    var innerTranslate = slideProgress * innerOffset;
    swiper.slides[i].querySelector('.slide-inner').style.transform =
      'translate3d(' + innerTranslate + 'px, 0, 0)';
  }
};
export const swiperTouchStart = (swiper) => {
  for (var i = 0; i < swiper.slides.length; i++) {
    swiper.slides[i].style.transition = '';
  }
};
export const swiperSetTransition = (swiper) => {
  const speed = swiper?.passedParams?.speed ? swiper.passedParams.speed : 1000;
  for (var i = 0; i < swiper.slides.length; i++) {
    swiper.slides[i].style.transition = speed + 'ms';
    swiper.slides[i].querySelector('.slide-inner').style.transition = speed + 'ms';
  }
};

export const defaultSwiperAnimTime = () => {
  return 6000;
};

// input  -> "Hello &amp; welcome to the &lt;coding world&gt;!";
// output -> "Hello & welcome to the <coding world>!"
export const decodeXML = (str) => {
  // Create an instance of DOMParser
  const parser = new DOMParser();
  // Parse the string as HTML
  const doc = parser.parseFromString(str, 'text/html');
  // The textContent of the body will be the decoded string
  return doc.documentElement.textContent;
};

export const checkTimeDifference = (timestamp) => {
  const givenTime = timestamp && moment(Number(timestamp));
  const currentTime = moment();
  const timeDifference = currentTime && givenTime && currentTime.diff(givenTime, 'hours');
  if (timeDifference < 24) {
    return true;
  } else {
    return false;
  }
};
